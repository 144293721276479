import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login/index.vue";
import home from "../views/home/index.vue";
import vehicleResult from "../views/vehicleResult/index.vue";
import searchResult from "../views/searchResult/index.vue";
import searchResultTable from "../views/searchResult/table.vue";
import mainGroup from "../views/mainGroup/index.vue";
import mainGroupEngine from "../views/mainGroup/engine.vue";
import mainGroupSub from "../views/mainGroup/subgroups.vue";

Vue.use(VueRouter);

import { service } from "@/service/index";

const routes = [
	{
		// 登陆页
		path: "/login",
		name: "login",
		component: login,
	},
	{
		path: "/",
		name: "index",
		component: login,
		redirect: "/login",
	},
	{
		// 首页
		path: "/home",
		component: home,
		meta: {
			keepAlive: true,
		},
	},
	{
		path: "/vehicleResult",
		name: "vehicleResult",
		component: vehicleResult,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/searchResult",
		name: "searchResult",
		component: searchResult,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/mainGroupEngine",
		name: "mainGroupEngine",
		component: mainGroupEngine,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/mainGroup",
		name: "mainGroup",
		component: mainGroup,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/mainGroupSub",
		name: "mainGroupSub",
		component: mainGroupSub,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/searchResultTable",
		name: "searchResultTable",
		component: searchResultTable,
		meta: {
			keepAlive: false,
		},
	},

	{
		path: "/:catchAll(.*)",
		name: "not-found",
		component: home,
	},
];

const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
	if (!to.meta.keepAlive) window.scrollTo({ top: 0, behavior: "smooth" });
	if (savedPosition) {
		return savedPosition;
	} else {
		return false;
	}
};

const router = new VueRouter({
	mode: "hash",
	routes,
	scrollBehavior,
});

// 路由后置守卫
router.afterEach(() => {
	// 在每次路由切换后，关闭所有活动的 loading 实例
	service.closeAllLoadings();
});

export default router;

/*
  session 设置：
    1. token
    2. name
    3. type
    4. tid
    5. sid
    5. 系统信息 info
 */
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) {
		// 判断该路由是否需要登录权限
		if (sessionStorage.getItem("token") === "true") {
			// 判断本地是否存在token
			next();
		} else {
			// 未登录,跳转到登陆页面
			next({
				path: "/login",
				query: { redirect: to.fullPath },
			});
		}
	} else {
		// 不需要登陆权限的页面，如果已经登陆，则跳转主页面
		if (sessionStorage.getItem("token") === "true") {
			const t = sessionStorage.getItem("type");
			next("/" + t);
		} else {
			next();
		}
	}
});
