let host = "";
if (process.env.NODE_ENV === "development") {
	// host = "http://192.168.10.132:7070/jeecg-boot";
	// 测试
	// host = "http://133.167.89.121:5888";
	// 正式
	host = "http://49.212.146.117:5888";

	// #endif
} else {
	// 测试
	// host = "http://133.167.89.121:5888";
	// 正式
	host = "http://49.212.146.117:5888";
}
export default host;
