/**
 * @description: 回到顶部
 * @return {*}
 */
function scrollTo() {
	window.scrollTo({
		top: 0,
		behavior: "smooth", // 添加该行以实现平滑滚动效果
	});
}

/**
 * @description: 校验两个对象是否相等
 * @return {*}
 */
function ifObjCompare(object1, object2) {
	var o1keys = Object.keys(object1);
	var o2keys = Object.keys(object2);
	if (o2keys.length !== o1keys.length) return false;
	for (let i = 0; i <= o1keys.length - 1; i++) {
		let key = o1keys[i];
		if (!o2keys.includes(key)) return false;
		if (object2[key] !== object1[key]) return false;
	}
	return true;
}

// 数组去重
function uniqueByField(arr, key) {
	return arr.reduce((acc, current) => {
		// 检查当前对象的字段值是否已经存在于结果数组中
		if (!acc.find((item) => item[key] === current[key])) {
			acc.push(current);
		}
		return acc;
	}, []);
}

function sortCharAt(arr, key1, key2, key3) {
	// 辅助函数：将字符转换为其在字母表中的顺序
	const alphabeticalOrder = (char) => {
		const lowerChar = char.toLowerCase();
		return lowerChar.charCodeAt(0) - "a".charCodeAt(0);
	};

	return arr.sort((a, b) => {
		// 1. 首先按 key1 排序
		let valueA1 = a[key1];
		let valueB1 = b[key1];

		if (valueA1 < valueB1) return -1;
		if (valueA1 > valueB1) return 1;

		// 2. 如果 key1 相同，再按 key2 的首字母排序
		let valueA2 = a[key2];
		let valueB2 = b[key2];

		if (valueA2 && valueB2) {
			let orderA2 = alphabeticalOrder(valueA2.charAt(0));
			let orderB2 = alphabeticalOrder(valueB2.charAt(0));

			if (orderA2 < orderB2) return -1;
			if (orderA2 > orderB2) return 1;

			// 3. 如果 key2 的首字母相同，再比较整个 key2
			if (valueA2 < valueB2) return -1;
			if (valueA2 > valueB2) return 1;
		}

		// 4. 如果 key2 的整个字符串也相同，再按 key3 的数值排序
		let startTimeA = parseInt(a[key3]);
		let startTimeB = parseInt(b[key3]);

		if (startTimeA < startTimeB) return -1;
		if (startTimeA > startTimeB) return 1;

		return 0;
	});
}

// 逐位排列
function customSort(arr, key) {
	// 辅助函数：判断字符是否是数字
	const isDigit = (char) => /\d/.test(char);

	// 辅助函数：比较两个字符，数字 > 字母
	const compareChar = (charA, charB) => {
			const digitA = isDigit(charA);
			const digitB = isDigit(charB);

			if (digitA && digitB) {
					// 都是数字，按数值排序
					return charA - charB;
			} else if (digitA) {
					// A 是数字，B 不是，数字优先
					return 1;
			} else if (digitB) {
					// B 是数字，A 不是，数字优先
					return -1;
			} else {
					// 都是字母，按字母顺序排序
					if (charA < charB) return -1;
					if (charA > charB) return 1;
					return 0;
			}
	};

	return arr.sort((a, b) => {
			const valueA = a[key];
			const valueB = b[key];
			const lenA = valueA.length;
			const lenB = valueB.length;
			const minLength = Math.min(lenA, lenB);

			// 逐位比较每个字符
			for (let i = 0; i < minLength; i++) {
					const charA = valueA.charAt(i);
					const charB = valueB.charAt(i);

					const comparison = compareChar(charA, charB);
					if (comparison !== 0) return comparison;
			}

			// 如果到目前为止都相等，较长的字符串优先
			if (lenA < lenB) return -1;
			if (lenA > lenB) return 1;

			return 0;
	});
}

// 是否为6位数字
function isSixDigitNumber(str) {
	return /^\d{6}$/.test(str);
}

export default { scrollTo, ifObjCompare, sortCharAt, isSixDigitNumber, uniqueByField ,customSort};
