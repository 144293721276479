// import axios from "axios";
// import { getToken } from "@/utils/setToken.js";
// import { Message, Loading } from "element-ui";
// import { translations } from "./translations";

// const translate = (error_code) => {
// 	return translations[error_code] || error_code;
// };

// class Reuqest {
// 	// loading;
// 	constructor(config) {
// 		this.instance = axios.create(config);

// 		// 添加所有实例都有的拦截器
// 		this.instance.interceptors.request.use(
// 			(config) => {
// 				config.headers["X-Access-Token"] = getToken("token");
// 				// 显示 loading
// 				// console.log("config", config);
// 				if (config.loadingInstance) config.loadingInstance.close();
// 				if (config.loading) {
// 					config.loadingInstance = Loading.service({
// 						lock: true,
// 						text: "Loading",
// 						spinner: "el-icon-loading",
// 						background: "rgba(0, 0, 0, 0.7)",
// 					});
// 				}

// 				return config;
// 			},
// 			(err) => {
// 				return err;
// 			}
// 		);
// 		this.instance.interceptors.response.use(
// 			(res) => {
// 				if (res.config.loadingInstance) res.config.loadingInstance.close();
// 				return res.data;
// 			},
// 			(err) => {
// 				Message({
// 					type: "error",
// 					message: "Program exception, please contact customer service",
// 				});
// 				if (err.config && err.config.loadingInstance) err.config.loadingInstance.close();

// 				return err;
// 			}
// 		);
// 	}

// 	requset(config) {
// 		return new Promise((resolve, reject) => {
// 			this.instance
// 				.request(config)
// 				.then((res) => {
// 					if (config.loadingInstance) config.loadingInstance.close();
// 					if (res.error_code && res.error_code != 0 && config.message != false) {
// 						Message({
// 							type: "error",
// 							message: translate(res.error_code),
// 						});
// 					}

// 					resolve(res);
// 				})
// 				.catch((err) => {
// 					if (config.loadingInstance) config.loadingInstance.close();
// 					reject(err);
// 				});
// 		});
// 	}

// 	get(config) {
// 		if (!config.params.key) config.params.key = "argos20240512a049f8c83cd7ea7b2ss";

// 		for (const key in config.params) {
// 			if (!config.params[key]) delete config.params[key];
// 		}

// 		return this.requset({ ...config, method: "GET" });
// 	}

// 	post(config) {
// 		return this.requset({ ...config, method: "POST" });
// 	}

// 	delete(config) {
// 		return this.requset({ ...config, method: "DELETE" });
// 	}

// 	patch(config) {
// 		return this.requset({ ...config, method: "PATCH" });
// 	}

// 	put(config) {
// 		return this.requset({ ...config, method: "PUT" });
// 	}
// }

// export default Reuqest;

import axios from "axios";
import { getToken } from "@/utils/setToken.js";
import { Message, Loading } from "element-ui";
import { translations } from "./translations";

const translate = (error_code) => {
	return translations[error_code] || error_code;
};

class Reuqest {
	constructor(config) {
		this.instance = axios.create(config);
		this.activeLoadings = new Set(); // 用来保存活跃的 loading 实例

		this.instance.interceptors.request.use(
			(config) => {
				// config.headers["X-Access-Token"] = getToken("token");
				if (config.loading) {
					// 显示 loading
					const loadingInstance = Loading.service({
						lock: true,
						text: "Loading",
						spinner: "el-icon-loading",
						background: "rgba(0, 0, 0, 0.7)",
					});
					// 保存 loading 实例以便后续关闭
					this.activeLoadings.add(loadingInstance);
					config.loadingInstance = loadingInstance;
				}

				return config;
			},
			(err) => {
				return Promise.reject(err);
			}
		);

		this.instance.interceptors.response.use(
			(res) => {
				// 关闭 loading
				if (res.config.loadingInstance) {
					res.config.loadingInstance.close();
					this.activeLoadings.delete(res.config.loadingInstance);
				}
				return res.data;
			},
			(err) => {
				// 关闭 loading
				if (err.config && err.config.loadingInstance) {
					err.config.loadingInstance.close();
					this.activeLoadings.delete(err.config.loadingInstance);
				}

				// 显示错误信息
				Message({
					type: "error",
					message: "Program exception, please contact customer service",
				});

				return Promise.reject(err);
			}
		);
	}

	requset(config) {
		return new Promise((resolve, reject) => {
			this.instance
				.request(config)
				.then((res) => {
					// 关闭 loading
					if (config.loadingInstance) {
						config.loadingInstance.close();
						this.activeLoadings.delete(config.loadingInstance);
					}

					// 检查响应中的错误码
					if (res.error_code && res.error_code != 0 && config.message !== false) {
						Message({
							type: "error",
							message: translate(res.error_code),
						});
					}

					resolve(res);
				})
				.catch((err) => {
					// 关闭 loading
					if (config.loadingInstance) {
						config.loadingInstance.close();
						this.activeLoadings.delete(config.loadingInstance);
					}
					reject(err);
				});
		});
	}

	get(config) {
		if (!config.params) config.params = {};
		if (!config.params.key) config.params.key = "argos20240512a049f8c83cd7ea7b2ss";

		for (const key in config.params) {
			if (!config.params[key]) delete config.params[key];
		}

		return this.requset({ ...config, method: "GET" });
	}

	post(config) {
		return this.requset({ ...config, method: "POST" });
	}

	delete(config) {
		return this.requset({ ...config, method: "DELETE" });
	}

	patch(config) {
		return this.requset({ ...config, method: "PATCH" });
	}

	put(config) {
		return this.requset({ ...config, method: "PUT" });
	}

	// 添加一个方法来关闭所有活动的 loading 实例
	closeAllLoadings() {
		this.activeLoadings.forEach((loadingInstance) => loadingInstance.close());
		this.activeLoadings.clear();
	}
}

export default Reuqest;
