import { service } from "@/service/index";

// 短码查询
export function getChassisNo(params) {
	return service.get({ url: "/api/chassis_no", params });
}

// 九位码、短码查询
export function getMixCar(params) {
	return service.get({ url: "/api/mix_car", params });
}

// 短码获取精准海外车型(hwvin)
export function getHwvin(params) {
	return service.get({ url: "/api/hwvin", params });
}

// 根据标准名获取指定OE
export function getOeSearch(params) {
	return service.get({ url: "/api/oe_search", params });
}

// OE反查EPC车型信息(Oe_car)(日本)
export function getOeCar(params) {
	return service.get({ url: "/api/oe_car", params, loading: true });
}

// OE反查基础信息(Oe_info)(日本)
export function getOeInfo(params) {
	return service.get({ url: "/api/oe_info", params, loading: true });
}

// OE查询EPC替换件信息(Replace_oe)(日本)
export function getReplaceOe(params) {
	return service.get({ url: "/api/replace_oe", params, message: false });
}

// 配件价格(oe_price)(日本)
export function getOePrice(params) {
	return service.get({ url: "/api/oe_price", params, message: false });
}

// OE反查EPC车型信息(Oe_car)(中国)
export function getOeCarCn(params) {
	return service.get({ url: "/api/oe_car_cn", params });
}

// OE反查基础信息(Oe_info)(中国)
export function getOeInfoCn(params) {
	return service.get({ url: "/api/oe_info_cn", params });
}

// OE查询EPC替换件信息(Replace_oe)(中国)
export function getReplaceOeCn(params) {
	return service.get({ url: "/api/replace_oe_cn", params, message: false });
}

// 配件价格(oe_price)(中国)
export function getOePriceCn(params) {
	return service.get({ url: "/api/oe_price_cn", params, message: false });
}

// 车型栏目搜索
export function getCarFields(params) {
	return service.get({ url: "/api/car_fields", params });
}
// 车型详情搜索
export function getSearchCar(params) {
	let _params = {};
	if (params) {
		_params = {};
		for (const key in params) {
			const newKey = key.toLowerCase().replace(/\s+/g, "_");
			_params[newKey] = params[key];
		}
	} else {
		_params = params;
	}

	return service.get({ url: "/api/search_car", params: _params });
}
// 主组查询(Maingroup)
export function getMainGroup(params) {
	return service.get({ url: "/api/maingroup", params });
}

// 子组查询(Subgroup)
export function getSubGroup(params) {
	return service.get({ url: "/api/subgroup", params });
}

// 子组图号坐标查询(Pos)
export function getPos(params) {
	return service.get({ url: "/api/pos", params });
}

// OE查询(Oe)
export function getOe(params) {
	return service.get({ url: "/api/oe", params });
}
