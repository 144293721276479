import navbar from "@/components/navbar/index.vue";
import breadcrumb from "@/components/breadcrumb/index.vue";
import vehicleInfo from "@/components/vehicleInfo/index";
import searchResult from "@/components/searchResult/index";
import colorBox from "@/components/colorBox/index";
// import vehicleBox from "@/components/vehicleBox/index";
import menuComp from "@/components/menu/index";
import searchGroup from "@/components/searchResult/group.vue";
import { getChassisNo, getHwvin, getMainGroup, getSubGroup, getOeSearch } from "@/service/api/index.js";
export const commonMixins = {
	components: { navbar, breadcrumb, vehicleInfo, searchResult, colorBox, menuComp, searchGroup },
	data() {
		return {
			query: null,
			resultData: null,
			vehicleList: null,
			mainGroup: [],
			subGroup: [],
			storageSubGroup: [],
		};
	},
	methods: {
		async leftChange({ item }) {
			this.$tools.scrollTo();
			this.getSubGroupApi(item.cate1_no);
		},
		searchChange(val) {
			// console.log(val);
			if (!val) return (this.subGroup = this.storageSubGroup);
			const result = this.subGroup.filter((item) => {
				return item.cate_min_name.toLowerCase().search(val.toLowerCase()) !== -1;
			});

			this.subGroup = result;
		},
		// 短码获取精准海外车型(hwvin)
		async getVehicle() {
			const { datas } = await getHwvin(this.query);
			this.vehicleList = datas.car_info;
		},
		// 短码查询
		async getResult() {
			const { datas } = await getChassisNo(this.query);
			this.resultData = datas;
		},
		// 主组查询(Maingroup)
		async getMainGroupApi(bool = true) {
			const { datas } = await getMainGroup({ ...this.query, ...(this.resultData ? { hw_id: this.resultData.veh_id } : {}) });
			console.log("getMainGroupApi", datas);
			this.mainGroup = datas.items;

			if (this.mainGroup.length && bool) {
				this.getSubGroupApi(datas.items[0].cate1_no);
			}
		},
		// 子组查询(Subgroup)
		async getSubGroupApi(cate1_no) {
			const { datas } = await getSubGroup({ ...this.query, cate1_no, ...(this.resultData ? { hw_id: this.resultData.veh_id } : {}) });

			this.storageSubGroup = datas.items;
			this.subGroup = datas.items;
		},
		// 根据标准名获取指定OE(oe_search)
		async getOeSearchApi(bool = true) {
			// console.log("this.query", this.query);
			const { datas } = await getOeSearch(this.query);
			// console.log("datas", datas);
			this.mainGroup = datas.items;
			if (this.mainGroup.length && bool) {
				this.getSubGroupApi(datas.items[0].cate1_no);
			}
		},
	},
};
