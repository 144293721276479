<template>
	<div class="page" v-if="query">
		<navbar />
		<breadcrumb :list="breadcrumbList" />

		<div class="main flex-direction-column">
			<div class="result-box">
				<vehicleInfo v-if="query.type == 1" :resultData="resultData" />
				<searchResult :isShow="false" />
				<colorBox v-if="query.type == 1" :resultData="resultData" />
				<vehicleBox v-if="query.type == 1" :list="vehicleList" />
				<vehicleList v-else :vehicleList="[vehicleList]" />
				<div class="specific-box">
					<div class="specific-title">
						<img src="@/assets/icon/reset.png" alt="" />
						<span>One-shot search for specific items</span>
					</div>

					<div class="back transition flex" @click="$router.go(-1)">
						<img src="@/assets/icon/back-icon.png" alt="" />
						<span>Back</span>
					</div>
					<menuComp v-model="current" :isShowLeft="false" :mainGroup="mainGroup" :subGroup="subGroup" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { commonMixins } from "@/mixins/common.js";
import vehicleList from "@/components/vehicleList/index";
import vehicleBox from "@/components/vehicleBox/index";
export default {
	mixins: [commonMixins],
	components: { vehicleBox, vehicleList },
	data() {
		return {
			current: 0,
			activeName: "Vulnerable parts",
			tabs: [
				{
					label: "Vulnerable parts",
					name: "Vulnerable parts",
				},
				{
					label: "Appearance parts",
					name: "Appearance parts",
				},
			],
			searchVal: "",

			breadcrumbList: [],
		};
	},

	async mounted() {
		this.query = this.$route.query;
		this.query.type = Number(this.query.type);

		this.breadcrumbList = [
			{
				name: this.query.chassis_no || this.query.bread,
				path: "/vehicleResult",
			},
			{
				name: this.query.standardnames,
			},
		];
		// console.log(this.breadcrumbList);
		if (this.query.type === 1) {
			await this.getVehicle();
			await this.getResult();
		} else {
			this.vehicleList = JSON.parse(sessionStorage.getItem("vehicleList"));
		}

		this.getOeSearchApi(true);
	},

	methods: {
		handleClick(val) {},
	},
};
</script>
<style scoped lang="less">
.page {
	background-color: #f8f9fb !important;
	padding-bottom: 30px;
	min-height: calc(100vh - 30px);
}
.result-box {
	background: #fff;
	// margin-top: 15px;
	padding: 25px;
}

.main {
	padding: 25px;
	background-color: #fff;
	width: 1350px;
	margin: 15px auto 0px auto;
	border: 1px solid #e6e6e6;
	.header {
		background: #fffaf5;
	}
	.back {
		cursor: pointer;
		padding: 12px 18px;
		width: fit-content;
		background: #f7f7f7;
		border-radius: 50px;
		margin-top: 15px;
		img {
			width: 16px;
			height: 16px;
		}
		span {
			font-weight: 500;
			font-size: 14px;
			color: #000000;
			margin-left: 10px;
			display: block;
		}
	}
}

.specific-box {
	margin-top: 30px;

	.specific-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 30px;
		img {
			margin-right: 6px;
		}
	}
	/deep/ .el-tabs__active-bar {
		background-color: transparent !important;
	}
	/deep/ .el-tabs__item.is-active {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #fd7e14;
		background: #fffaf5;
		border-bottom: 2px solid #fd7e14;
	}
	/deep/ .el-tabs__item {
		width: 243px;
		// padding:0 26px;
		height: 52px;
		line-height: 25px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 18px;
		color: #000000;
		white-space: pre-line;
		display: flex;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		border-bottom: 2px solid #e2e2e2;
	}
	/deep/ .el-tabs__nav {
		display: flex;
	}
	/deep/ .el-tabs__nav-wrap::after {
		height: 0;
	}
}
.bread-box {
	width: 1400px;
	margin: 0 auto;
	height: 60px;
	display: flex;
	align-items: center;
	.el-icon-location {
		margin-right: 6px;
		font-size: 16px;
	}
	.breadName {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #fd7e14;
		cursor: pointer;
	}
	.separator-icon {
		display: inline-block;
		margin-left: 6px;
		margin-right: 6px;
	}
}
</style>
