<template>
	<div class="search" :style="{ width: width + 'px', left: left + 'px', top: top + 'px' }">
		<div class="item flex-space-between" v-for="item in list" :key="item.label" @click="itemClick(item)">
			<div class="left">{{ item.label }}</div>
			<div class="icon" @click.stop="deleteBtn(item)">
				<i class="el-icon-close"></i>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default() {
				return [];
			},
		},
		width: {
			type: [Number, String],
			default() {
				return 650;
			},
		},
		left: {
			type: [Number, String],
			default() {
				return 0;
			},
		},
		top: {
			type: [Number, String],
			default() {
				return 60;
			},
		},
	},
	data() {
		return {};
	},

	mounted() {},

	methods: {
		itemClick(item) {
			this.$emit("change", item.label);
		},
		deleteBtn(item) {
			this.$emit("delete", item.label);
		},
	},
};
</script>
<style scoped lang="less">
.search {
	background: #ffffff;
	border: 1px solid #fd7e14;
	// width: 650px;
	max-height: 185px;
	overflow-y: auto;
	padding: 5px 0px;

	position: absolute;
	z-index: 99;
	border-radius: 4px;
	.item {
		font-size: 16px;
		color: #000000;
		cursor: pointer;
		// margin-bottom: 10px;
		width: 100%;
		padding: 5px 0;
		// padding: 5px 19px;
		&:hover {
			background-color: #fffaf5 !important;
			color: #fd7e14;
			.icon {
				display: block !important;
			}
		}
		.left {
			flex: 1;
			margin: 0 0px 0 19px;
		}
		.icon {
			margin-right: 19px;
			display: none;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
