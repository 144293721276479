import Vue from "vue";
// import axios from 'axios'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import modal from "./plugins/modal";
import tools from "./utils/tools.js";
import VCharts from "v-charts";
import host from "./utils/index.js";
import service from "./utils/service";
import Print from "vue-print-nb";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
Vue.use(VCharts);
Vue.use(Print); //注册
Vue.prototype.host = host;
Vue.prototype.service = service;
// Vue.prototype.$axios = axios
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.prototype.$modal = modal;
Vue.prototype.$tools = tools;

const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
	if (!to.meta.keepAlive) window.scrollTo({ top: 0, behavior: "smooth" });
	if (savedPosition) {
		return savedPosition;
	} else {
		return false;
	}
};

new Vue({
	router,
	store,
	scrollBehavior,
	render: (h) => h(App),
}).$mount("#app");

if (process.env.NODE_ENV === "production") {
	if (window) window.console.log = function () {};
}
