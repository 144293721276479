<template>
	<div class="mains flex-space-between">
		<div class="vehicle-box" @click="jumpBtn">
			<div v-for="(item, index) in filter" :key="index" class="vehicle-item" v-show="item.name_en != 'Series' && item.name_en != 'HW_ID' && item.name_en != 'Country'">
				<span>{{ item.name_en }}:</span>
				{{ item.value }}
			</div>
		</div>
		<div class="icon">
			<img src="@/assets/icon/arrow-right-active.png" alt="" />
		</div>
	</div>
</template>

<script>
import { commonMixins } from "@/mixins/common.js";
export default {
	mixins: [commonMixins],

	props: {
		list: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	data() {
		return {
			filter: [],
			result: null,
			vehicleInfo: {},
		};
	},

	async mounted() {
		this.query = this.$route.query;
		if (this.query.val) {
			this.query.chassis_no = this.query.val;
		}
		await this.getVehicle();
		await this.getResult();
	},

	methods: {
		async jumpBtn() {
			await this.getMainGroupApi(false);
			if (this.mainGroup.length) await this.getSubGroupApi(this.mainGroup[0].cate1_no);
			sessionStorage.setItem("vehicleList", JSON.stringify(this.vehicleInfo));
			// 若子组只有一个 那么直接跳转 mainGroupEngine
			// console.log(this.mainGroup, this.subGroup);
			// return;
			if (this.subGroup.length === 1) {
				const { cate1_no, oe_name, cate1_name } = this.mainGroup[0];
				const { cate_min_no, cate_min_name } = this.subGroup[0];
				this.$router.push({
					path: "/mainGroupEngine",
					query: {
						...this.$route.query,
						cate_min_no,
						cate1_no,
						cate_min_name,
						cate_main_name: oe_name || cate1_name,
					},
				});
			} else {
				this.$router.push({
					path: "/mainGroup",
					query: { ...this.$route.query },
				});
			}
		},
	},
	watch: {
		list: {
			handler(val) {
				let sopValue = "";
				let eopValue = "";
				let current = null;
				this.vehicleInfo = {};
				let filter = val.filter((item) => item.name_en !== "HW_ID" && item.name_en !== "Series" && item.name_en !== "Country");

				filter.forEach((item, index) => {
					this.vehicleInfo[item.name_en] = item.value;
					if (item.name_en === "SOP") {
						sopValue = item.value;

						if (current == null || current > index) {
							current = index;
							console.log("current", current);
						}
					} else if (item.name_en === "EOP") {
						eopValue = item.value;

						if (current == null || current > index) {
							current = index;
							console.log("current", current);
						}
					}
				});

				const sopEop = {
					name_en: "SOP-EOP",
					value: `${sopValue}-${eopValue}`,
				};

				filter = filter.filter((item) => item.name_en !== "SOP" && item.name_en !== "EOP");

				filter.splice(current, 0, sopEop);
				this.filter = filter;
				console.log("val", this.filter);
			},
			immediate: true,
		},
	},
};
</script>

<style scoped lang="less">
.mains {
	// background: #f7f7f7;
	background: #f4f4f4;
	border-radius: 4px;
	padding: 30px 20px 13px 30px;
	margin-top: 30px;
}
.icon {
	margin-left: 10px;
	width: 18px;
	height: 18px;
	img {
		width: 18px;
		height: 18px;
	}
}
.vehicle-box {
	// border-radius: 4px;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex: 1;
	// width: calc(100% - 28px);
	// border: 1px solid #000;

	cursor: pointer;
	.vehicle-item {
		// width: 300px;
		width: 24%;

		margin-right: 10px;
		margin-bottom: 17px;
	}
}
</style>
