import { setToken, getToken } from "@/utils/setToken.js";
export const searchHistoryMixins = {
	data() {
		return {
			focusParams: {
				model: "",
				type: "",
				data: [],
			},
			isShowHistory: false,
		};
	},
	methods: {
		focus(event, type, model) {
			this.isShowHistory = true;
			this.focusParams.type = type;
			this.focusParams.model = model;

			let result = getToken(this.focusParams.type);
			if (!result) return;
			result = JSON.parse(result);

			this.focusParams.data = result.filter((item) => item.label !== "");
			console.log(this.focusParams.data, "this.focusParams.data", type, model);
		},
		blur() {},
		searchHistoryChange(val) {
			this[this.focusParams.model] = val;
			this.isShowHistory = false;
		},
		searchHistoryDelete(val) {
			const index = this.focusParams.data.findIndex((item) => item.label === val);
			if (index != -1) this.focusParams.data.splice(index, 1);
			setToken(this.focusParams.type, JSON.stringify(this.focusParams.data));
		},
		setSearchHistoryData(data, model, type) {
			if (data) {
				console.log(data, model, type);
				// this.focusParams.type = type;
				// this.focusParams.model = model;

				let history = [{ label: data }];
				let result = getToken(type);
				console.log(history, result, "result", this.focusParams);
				if (!result) {
					setToken(type, JSON.stringify(history));
					this.focusParams.data = history;
				} else {
					result = JSON.parse(result);
					const index = result.findIndex((item) => item.label === data);
					if (index === -1) {
						history = [...history, ...result];
						setToken(type, JSON.stringify(history));
						this.focusParams.data = history;
					}
				}

				// let history = [{ label: data }];
				// let result = getToken(type);
				// console.log(history,result,'result');
				// if (!result) {
				// 	setToken(type, JSON.stringify(history));
				// 	this.focusParams.data = history;
				// } else {
				// 	result = JSON.parse(result);
				// 	const index = result.findIndex((item) => item.label === data);
				// 	if (index === -1) {
				// 		history = [...history, ...result];
				// 		setToken(type, JSON.stringify(history));
				// 		this.focusParams.data = history;
				// 	}
				// }
			} else {
				let history = [{ label: this[this.focusParams.model] }];
				let result = getToken(this.focusParams.type);
				console.log(history, result, "result");
				if (!result) {
					setToken(this.focusParams.type, JSON.stringify(history));
					this.focusParams.data = history;
				} else {
					result = JSON.parse(result);
					const index = result.findIndex((item) => item.label === this[this.focusParams.model]);
					if (index === -1) {
						history = [...history, ...result];
						setToken(this.focusParams.type, JSON.stringify(history));
						this.focusParams.data = history;
					}
				}
			}
			this.isShowHistory = false;
		},
		handleClick() {
			this.focusParams.data = [];
		},
	},
};
