<template>
	<div class="color-box" v-if="resultData">
		<div class="color-item"><span class="label">Date of production: </span>{{ resultData.production_date || "-" }}</div>
		<div class="color-item"><span class="label">ext color: </span>{{ resultData.ext_color || "-" }}</div>
		<div class="color-item"><span class="label">int color: </span>{{ resultData.int_color || "-" }}</div>
	</div>
</template>

<script>
export default {
	props: {
		resultData: {
			type: Object,
			default() {
				return {};
			},
		},
	},

	data() {
		return {};
	},

	mounted() {},

	methods: {},
};
</script>

<style scoped lang="less">
.color-box {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 30px;
	.color-item {
		margin-right: 58px;
		span {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #999999;
		}
	}
}
</style>
