<template>
	<div class="container">
		<div class="bread-box">
			<i class="el-icon-location"></i>
			<div>Location：</div>

			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
				<template v-if="list.length">
					<el-breadcrumb-item v-for="(item, index) in list" :key="index" class="flex">
						<div style="cursor: pointer" @click="itemClick(item, index)">{{ item.name }}</div>
					</el-breadcrumb-item>
				</template>
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
export default {
	name: "JapanApiIndex",
	props: {
		list: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			query: null,
		};
	},

	mounted() {},

	methods: {
		itemClick(item, index) {
			if (index === this.list.length - 1) return;
			this.$router.push({
				path: item.path,
				query: this.query,
			});
		},
	},
	watch: {
		list: {
			handler(val) {
				// console.log("$route", this.$route, val);
				const { name, path, query } = this.$route;
				if (name === "vehicleResult") delete query.standardnames;
				this.query = query;
			},
			immediate: true,
		},
	},
};
</script>

<style scoped lang="less">
.container {
	background: #fff;
	.bread-box {
		width: 1400px;
		margin: 0 auto;
		height: 60px;
		display: flex;
		align-items: center;
		.el-icon-location {
			margin-right: 6px;
			font-size: 16px;
		}
		/deep/ .el-breadcrumb__item,
		/deep/ .el-breadcrumb__inner,
		/deep/ .el-breadcrumb__separator {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #fd7e14 !important;
		}
		.breadName {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #fd7e14;
			cursor: pointer;
		}
		.separator-icon {
			display: inline-block;
			margin-left: 6px;
			margin-right: 6px;
		}
	}
}
</style>
