<template>
	<div class="page" v-if="query">
		<navbar />
		<breadcrumb :list="breadcrumbList" />

		<div class="main flex-direction-column">
			<div class="result-box" v-if="query.type === 1">
				<colorBox style="margin-top: 0" :resultData="resultData" />
				<vehicleInfo style="margin-top: 20px" :resultData="resultData" />
			</div>
			<div class="line" v-if="query.type === 1"></div>
			<div class="result-box flex-direction-column" v-if="info">
				<div class="header flex-space-between">
					<div class="tabs flex-center">
						<div class="content flex">
							<i class="el-icon-arrow-left" @click="arrowBtn('pre')"></i>
							<span>{{ activeIndex + 1 }}/{{ info.items.length }}</span>
							<i class="el-icon-arrow-right" @click="arrowBtn('add')"></i>
						</div>

						<div class="back flex" @click="$router.go(-1)">
							<img src="@/assets/icon/back-icon.png" alt="" />
							<span>Back</span>
						</div>
					</div>
					<div class="total flex-space-between">
						<div class="count">Count:{{ tableData.length }}</div>
					</div>
				</div>

				<div class="item flex-space-between">
					<div class="left">
						<div class="basic flex-direction-column">
							<p>{{ info.items[activeIndex].cate2_opt2 }}</p>
							<p>{{ info.items[activeIndex].cate2_desc1 }}</p>
							<p>{{ info.items[activeIndex].cate2_desc2 || "" }}</p>
						</div>
						<div class="bd">
							<imageViewr v-show="info.items" ref="imageViewrRef" :pos="pos" :imageUrl="info.items[activeIndex].cate2_img" :coordinates="info.items[activeIndex].pos" @pos="posChange" />
						</div>
					</div>
					<div class="right">
						<el-table
							ref="table"
							height="calc(100% - 80px)"
							tooltip-effect="dark"
							:data="tableData"
							:header-cell-style="{ background: '#FD7E14', color: '#fff' }"
							style="width: 100%"
							:row-class-name="tableRowClassName"
							:default-expand-all="true"
							@selection-change="handleSelectionChange"
							@cell-click="handleCellClick"
							border
						>
							<el-table-column align="center" type="index" label="NO." width="50" key="index"> </el-table-column>
							<el-table-column align="center" type="selection" width="30"> </el-table-column>
							<el-table-column type="expand" class="expand-column" :width="width">
								<template slot-scope="props" v-if="props.row.remark">
									<div
										class="remark"
										style="width: 100%; overflow-x: auto; white-space: nowrap; cursor: pointer"
										:class="getActive(props.$index) != undefined ? 'active' : ''"
										@click="handleCellClick(props.row)"
									>
										<div style="margin-left: 49px; border-left: 1px solid #ebeef5; padding-left: 10px">Remark：{{ props.row.remark || "-" }}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="pos" label="PNC" key="PNC" width="80"> </el-table-column>
							<el-table-column align="center" prop="oe_name" label="OE_NAME" key="oe_name" width=""> </el-table-column>
							<el-table-column align="center" prop="start_time" label="START/END" key="start_time" width="80" :render-header="renderheader">
								<template slot-scope="props">
									<div>
										{{ props.row.start_time }}
										<br />
										{{ props.row.end_time }}
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="oe" label="OEM Code" key="OEM Code" width="120"> </el-table-column>
							<el-table-column align="center" prop="num" label="QTY" key="num" width="50"></el-table-column>
						</el-table>
						<div class="flex-end">
							<div class="btn" v-if="rowIndex.length" @click="submitBtn">Submit</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { commonMixins } from "@/mixins/common.js";
import { getPos, getOe } from "@/service/api/index.js";
import imageViewr from "./comp/imageViewr.vue";
export default {
	mixins: [commonMixins],
	components: { imageViewr },
	data() {
		return {
			breadcrumbList: [],
			tableData: [],
			info: null,
			tableActive: null,
			rowIndex: [],
			pos: [],
			activeIndex: 0,
			activeName: "",
			width: 0,
			checkTableData: [],
		};
	},

	mounted() {
		this.init();

		// console.log("1", this.$route.query.pos);
	},
	destroyed() {},
	computed: {},

	methods: {
		async init() {
			this.resetData();
			const query = { ...this.$route.query };
			this.query = this.$route.query;

			delete this.query.cate2_name;

			this.query.type = Number(this.query.type);

			if (this.query.standardnames) {
				this.breadcrumbList = [
					{
						name: this.query.chassis_no || this.query.bread || this.query.val,
						path: "/vehicleResult",
					},
					{
						name: this.query.standardnames,
						path: "/searchResult",
					},
					{
						name: "Main Group",
						path: "/mainGroup",
					},
					{
						name: query.cate_min_name,
					},
				];
			} else {
				this.breadcrumbList = [
					{
						name: this.query.chassis_no || this.query.bread || this.query.val,
						path: "/vehicleResult",
					},
					{
						name: "Main Group",
						path: "/mainGroup",
					},
					{
						name: query.cate_min_name,
					},
				];
			}

			if (this.query.type === 1) {
				await this.getVehicle();
				await this.getResult();
			}
			this.getPosApi();
		},
		// 重置 data 数据
		resetData() {
			Object.assign(this.$data, this.$options.data.call(this));
		},
		// 表头换行
		renderheader(h, { column, $index }) {
			return h("span", {}, [h("span", {}, column.label.split("/")[0]), h("br"), h("span", {}, column.label.split("/")[1])]);
		},

		submitBtn() {
			let filter = [];
			for (let index = 0; index < this.rowIndex.length; index++) {
				const element = this.rowIndex[index];
				filter.push(this.tableData[element]);
			}

			for (let index = 0; index < this.checkTableData.length; index++) {
				const element = this.checkTableData[index];
				const idx = filter.findIndex((item) => item.pos == element.pos);
				if (idx == -1) filter.push(element);
			}

			localStorage.setItem("subgroups", JSON.stringify(filter));

			this.$router.push({
				path: "/mainGroupSub",
				query: this.$route.query,
			});
		},
		async arrowBtn(type) {
			// console.log(this.activeIndex, this.info.items.length - 1);

			if (type === "pre") {
				if (this.activeIndex === 0) {
					this.activeIndex = this.info.items.length - 1;
				} else {
					this.activeIndex -= 1;
				}
			} else {
				if (this.activeIndex === this.info.items.length - 1) {
					this.activeIndex = 0;
				} else {
					this.activeIndex += 1;
				}
			}

			await this.getOeApi(this.info.items[this.activeIndex].cate2_no, false);

			this.$refs.imageViewrRef.resetImage();
		},
		async handleClick(val) {
			this.activeIndex = Number(val.index);

			await this.getOeApi(this.info.items[this.activeIndex].cate2_no);
			this.$refs.imageViewrRef.resetImage();
		},
		tableRowClassName({ row, rowIndex }) {
			row.index = rowIndex;

			return row.remark ? "has-remark" : "";
		},
		handleSelectionChange(val) {
			this.pos = [];
			this.rowIndex = [];
			if (!val.length) return this.scrollToTable();
			for (let index = 0; index < val.length; index++) {
				const element = val[index];

				this.pos.push(element.pos);
				this.rowIndex.push(element.index);

				if (index === val.length - 1) {
					this.scrollToTable(element.pos);
					// console.log(this.rowIndex);
				}
			}

			// console.log(this.rowIndex);
		},
		handleCellClick(row) {
			// console.log("row", row);
			const index = this.pos.findIndex((item) => item.pos === row.pos);
			if (index == -1) {
				this.pos.push(row.pos);
				this.rowIndex.push(row.index);
				this.toggleSelection([row]);
			} else {
				this.pos.splice(index, 1);
				this.rowIndex.splice(index, 1);
			}

			this.scrollToTable(row.pos);
		},
		posChange(index) {
			const { pos } = this.info.items[this.activeIndex].pos[index];

			const filterArr = this.tableData.filter((item) => item.pos === pos);

			if (filterArr.length) {
				for (let idx = 0; idx < filterArr.length; idx++) {
					const element = filterArr[idx];

					this.toggleSelection([this.tableData[element.index]]);

					if (idx === filterArr.length - 1) {
						this.scrollToTable(element.pos);
					}
				}
			}
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					const isSelected = this.$refs.table.selection.includes(row);
					if (!isSelected) {
						this.$refs.table.toggleRowSelection(row, true);
					}
				});
			} else {
				this.$refs.table.clearSelection();
			}
		},
		getActive(index) {
			return this.rowIndex.find((item) => item === index);
		},
		scrollToTable(pos) {
			if (!pos) {
				const rows = this.$refs.table.$el.querySelectorAll(".el-table__row");
				for (let i = 0; i < rows.length; i++) {
					const element = rows[i];
					element.style.background = "";
				}
				return;
			}

			const index = this.tableData.findIndex((item) => item.pos === pos);
			if (index !== -1) {
				this.tableActive = index;
				// console.log(index);
				this.$nextTick(() => {
					const tableBodyWrapper = this.$refs.table.$el.querySelector(".el-table__body-wrapper");
					const rows = this.$refs.table.$el.querySelectorAll(".el-table__row");
					let targetScrollTop = 0;
					for (let i = 0; i < index; i++) {
						targetScrollTop += rows[i].offsetHeight;
						if (this.tableData[i].remark) {
							targetScrollTop += 45; // 仅当 remark 存在时增加 45
						}
					}
					// console.log("targetScrollTop", targetScrollTop);
					const targetRowHeight = rows[index].offsetHeight;
					const boxHeight = tableBodyWrapper.clientHeight;

					// 添加滚动动画
					tableBodyWrapper.style.scrollBehavior = "smooth";

					for (let i = 0; i < rows.length; i++) {
						const element = rows[i];
						// console.log("this.getActive(i)", this.getActive(i));
						if (this.getActive(i) != undefined) {
							element.style.background = "#FFF1E2";
						} else {
							element.style.background = "";
						}
					}

					// 滚动到使目标行在可视区域的中间
					const scrollTop = targetScrollTop - (boxHeight / 2 - targetRowHeight / 2);
					tableBodyWrapper.scrollTop = scrollTop;
				});
			} else {
				this.$modal.msgWarning(`No data found ${pos}`);
			}
		},

		async getPosApi() {
			const { datas } = await getPos(this.query);
			this.info = datas;
			this.activeName = datas.items[0].cate2_no;
			this.getOeApi(datas.items[0].cate2_no);
		},
		async getOeApi(cate2_no, isSwitch = true) {
			let rowIndex = this.rowIndex;
			this.checkTableData = [];
			let tableData = [];
			if (rowIndex.length) {
				for (let index = 0; index < rowIndex.length; index++) {
					const element = rowIndex[index];
					tableData.push(this.tableData[element]);

					this.checkTableData.push(this.tableData[element]);
				}

				tableData = this.$tools.uniqueByField(tableData, "pos");

				// console.log("this.checkTableData", this.checkTableData);
			}

			// 置空表格数据
			this.tableData = [];

			const params = { ...this.query, cate2_no };
			const { datas } = await getOe(params);

			this.tableData = datas.items;

			this.$nextTick(() => {
				if (this.width === 0) this.width = 1;
				else this.width = 0;

				if (this.$route.query.quick) {
					// console.log("this.$route.query.pos", this.$route.query.pos);

					let _pos = this.$route.query.pos;

					if (!_pos) return;

					if (typeof _pos === "string") _pos = [_pos];

					// 快捷检索高亮
					let light = [];

					for (let index = 0; index < _pos.length; index++) {
						const ele = _pos[index];
						const filter = this.tableData.filter((item) => item.pos === ele);
						if (filter.length) light = [...filter, ...light];
					}

					if (isSwitch && !light.length && this.info.items.length - 1 !== this.activeIndex) {
						this.arrowBtn("add");
					} else {
						for (let index = 0; index < light.length; index++) {
							const ele = light[index];

							this.toggleSelection([this.tableData[ele.index]]);
						}
					}
				}

				if (rowIndex.length) {
					for (let index = 0; index < tableData.length; index++) {
						const { pos } = tableData[index];

						const filter = this.tableData.filter((item) => item.pos == pos);
						// console.log("filter", filter);

						if (filter.length) {
							for (let ii = 0; ii < filter.length; ii++) {
								const { index } = filter[ii];

								this.toggleSelection([this.tableData[index]]);
							}
						}
					}
				}
			});
		},
	},
	watch: {
		$route(val) {
			if (this.$refs.imageViewrRef) this.$refs.imageViewrRef.resetImage();
			this.init();
		},
	},
};
</script>

<style scoped lang="less">
::v-deep .el-table-column--selection .cell {
	padding: 0 !important;
}
::v-deep .el-table__header {
	height: 65px !important;
}
::v-deep .el-table__expand-icon--expanded {
	display: none !important;
}

::v-deep .el-tabs__item.is-active {
	color: #f56f24;
}

::v-deep .el-tabs__active-bar {
	background-color: #f56f24;
}

::v-deep .el-table__expanded-cell[class*="cell"] {
	padding: 0 !important;

	div {
		height: 45px;
		line-height: 45px;
		border-bottom: 1px solid #ebeef5;
		overflow-y: hidden;
	}
}

::v-deep .el-table__expanded-cell {
	border-bottom: none !important; /* 隐藏边框 */
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
	border-bottom: none !important;
}

::v-deep .el-table__row {
	td.el-table__cell {
		border-bottom: 1px solid #ebeef5 !important;
	}
}

::v-deep .has-remark {
	td.el-table_1_column_1 {
		border-bottom: none !important;
		position: relative;
		.cell {
			position: absolute;

			z-index: 9;
			width: 100%;
			height: calc(100% + 45px);
			top: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

::v-deep .el-table thead {
	.el-table__cell {
		border: none !important;
	}
}

.remark {
	/* 滚动条宽度 */
	&::-webkit-scrollbar {
		height: 5px !important;
	}

	&::-moz-scrollbar {
		height: 5px !important;
	}

	&::-ms-scrollbar {
		height: 5px !important;
	}
}

.btn {
	width: fit-content;
	font-size: 16px;
	background-color: #f56f24;
	color: #fff;
	padding: 10px 25px;
	border-radius: 25px;
	cursor: pointer;
	margin-top: 10px;
}

.active {
	background: #fff1e2;
}

.el-table::before {
	display: none;
}
.page {
	background-color: #f8f9fb !important;
	padding-bottom: 30px;
	min-height: calc(100vh - 30px);
}

.el-table .success-row {
	background: #f0f9eb;
}
.line {
	border-bottom: 1px solid #e6e6e6;
}
.main {
	background-color: #fff;
	width: 1400px;

	margin: 15px auto 0px auto;
	border: 1px solid #e6e6e6;
	.result-box {
		padding: 25px;
		.item {
			height: calc(100vh - 66px - 60px);
			.left {
				width: 621px;
				// position: relative;
				height: 100%;

				overflow: hidden;
				// margin-top: 90px;

				.left-main {
					position: relative;
					height: 100%;
					width: 100%;
					p {
						cursor: pointer;
						position: absolute;
						margin: 0;
						padding: 0;
						// border: 1px solid #000;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
			.bd {
				height: calc(100vh - 66px - 60px - 73px - 55px);
				border: 1px solid #e6e6e6;
			}
			.basic {
				// height: 70px;

				p {
					font-size: 16px;
					margin: 0;
					height: 22px;
					// border: 1px solid #000;
				}
			}
			.right {
				width: 716px;
				// flex: 1;
				height: 100%;
				// margin-left: 18px;
				.top {
					height: 30px;
					margin-bottom: 10px;
				}
			}
		}
	}
}
.bread-box {
	width: 1400px;
	margin: 0 auto;
	height: 60px;
	display: flex;
	align-items: center;
	.el-icon-location {
		margin-right: 6px;
		font-size: 16px;
	}
	.breadName {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #fd7e14;
		cursor: pointer;
	}
	.separator-icon {
		display: inline-block;
		margin-left: 6px;
		margin-right: 6px;
	}
}
.tabs {
	width: 621px;
	position: relative;
	// margin-bottom: 10px;
	.back {
		cursor: pointer;
		padding: 12px 18px;
		width: fit-content;
		background: #f7f7f7;
		border-radius: 50px;
		// margin-top: 15px;
		position: absolute;
		right: 0;
		img {
			width: 16px;
			height: 16px;
		}
		span {
			font-weight: 500;
			font-size: 14px;
			color: #000000;
			margin-left: 10px;
			display: block;
		}
	}
	.content {
		i {
			cursor: pointer;
			&:hover {
				color: #fd7e14;
			}
		}
		span {
			font-size: 16px;
			margin: 0 15px;
		}
	}
}
.total {
	flex: 1;
	margin-left: 15px;
}
</style>
