<template>
	<div>
		<!-- header -->
		<div class="header">
			<img src="../../assets/icon/logo.jpg" alt="" class="logo" />
		</div>
		<!-- banner -->
		<div class="banner">
			<img src="../../assets/icon/banner.png" alt="" style="width: 100%" />
		</div>
		<!-- 登录 -->
		<div class="login">
			<div class="top">
				<p class="tit"></p>
				<p class="txt">Welcome</p>
			</div>
			<div class="center">
				<el-form ref="form" :model="form" label-width="0px" :rules="rules">
					<el-form-item label="" prop="username">
						<el-input v-model="form.username" placeholder="Username"></el-input>
					</el-form-item>
					<el-form-item label="" prop="password">
						<el-input v-model="form.password" placeholder="Password"></el-input>
					</el-form-item>
					<el-form-item label="">
						<div class="remember">
							<el-checkbox v-model="checked">Remember</el-checkbox>
							<p>forget?</p>
						</div>
					</el-form-item>
					<el-form-item label="">
						<p class="loging" @click="submitForm('form')">Login</p>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		let validUsername = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("please enter username"));
			} else {
				if (value != "admin") {
					callback(new Error("Please enter the correct username"));
				} else {
					callback();
				}
			}
		};
		let validPassword = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("please enter password"));
			} else {
				if (value != "admin123") {
					callback(new Error("Please enter the correct password"));
				} else {
					callback();
				}
			}
		};
		return {
			form: {
				username: "",
				password: "",
			},
			checked: true,
			rules: {
				username: [{ validator: validUsername, trigger: "blur" }],
				password: [{ validator: validPassword, trigger: "blur" }],
			},
		};
	},
	created() {
		this.rememberLogin();
	},
	methods: {
		// 勾选了记住再次进页面回显
		rememberLogin() {
			let login = localStorage.getItem("login");
			if (login) {
				this.$set(this.form, "username", JSON.parse(login).username);
				this.$set(this.form, "password", JSON.parse(login).password);
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					// 是否勾选了记住
					if (this.checked) {
						localStorage.setItem("login", JSON.stringify(this.form));
						this.$router.push({
							path: "/home",
						});
					} else {
						localStorage.removeItem("login");
					}
				} else {
					return false;
				}
			});
		},
	},
};
</script>
<style scoped lang="less">
.header {
	height: 65px;
	background: #fff;
	display: flex;
	align-items: center;
	.logo {
		margin-left: 53px;
		// height: 75%;
		width: 320px;
		height: 50px;
	}
}
.login {
	width: 355px;
	height: 405px;
	background: #ffffff;
	box-shadow: 0px 2px 11px 0px rgba(106, 106, 106, 0.5);
	border-radius: 4px;
	border: 1px solid #d9d9d9;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 55px 22px 0 23px;
	.top {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 34px;
		margin-bottom: 55px;
		.tit {
			width: 4px;
			height: 34px;
			background: #fd7e14;
		}
		.txt {
			font-family: PingFangSC, PingFang SC;
			font-weight: 600;
			font-size: 25px;
			color: #333333;
			margin-left: 17px;
		}
	}
}
.center {
	/deep/ .el-form-item {
		margin-bottom: 30px;
	}
	/deep/ .el-input__inner {
		width: 355px;
		height: 55px;
		background: #f2f2f2;
		border-radius: 4px;
		// margin-bottom: 30px;
	}
}
.remember {
	line-height: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 20px;
	/deep/ .el-checkbox__inner {
		width: 13px;
		height: 13px;
		border-radius: 50%;
	}
	/deep/ .el-checkbox__label {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #989a9b;
	}
	p {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #0093ff;
		text-decoration-line: underline;
		cursor: pointer;
	}
	/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #fd7e14 !important;
		border-color: #fd7e14 !important;
	}
	/deep/ .is-checked .el-checkbox__label {
		color: #fd7e14 !important;
	}

	/deep/ .el-checkbox__input .el-checkbox__inner {
		background-color: #fff !important;
		border-color: #fd7e14 !important;
	}
}
.loging {
	width: 355px;
	height: 55px;
	background: #fd7e14;
	border-radius: 4px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
	line-height: 55px;
	text-align: center;
	cursor: pointer;
	margin-top: 0;
}
</style>
