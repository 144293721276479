<template>
	<div class="search-result-container">
		<div class="left flex">
			<div class="item">Select Main Group Subdivision：</div>
			<div class="item">Count：{{ subgroupsTotal || 0 }}</div>
		</div>
		<div class="search-form">
			<el-input v-model="searchVal" placeholder=""></el-input>
			<div @click="btnClick" class="search-box"><i class="el-icon-search"></i></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		subgroupsTotal: {
			type: Number,
			default() {
				return 0;
			},
		},
	},
	data() {
		return {
			searchVal: "",
		};
	},

	mounted() {},

	methods: {
		btnClick() {
			// if (!this.searchVal) return this.$modal.msgError("Please enter the content");
			this.$emit("change", this.searchVal);
		},
	},
};
</script>

<style scoped lang="less">
.search-result-container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.left {
		flex: 1;
		.item {
			font-size: 18px;
			color: #000000;
			&:nth-child(1) {
				width: 402px;
			}
			&:nth-child(2) {
				margin-left: 20px;
			}
		}
	}
	.search-box {
		width: 80px;
		height: 40px;
		line-height: 40px;
		background: #fd7e14;
		border-radius: 0px 100px 100px 0px;
		color: #fff;
		text-align: center;
	}
	.search-form {
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		/deep/ .el-input__inner {
			border-radius: 100px 0px 0px 100px;
			border-right: none;
		}
	}
	.setting {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #000000;
		i {
			font-size: 20px;
			margin-right: 10px;
		}
	}
}
</style>
