<template>
	<div class="page" v-if="query">
		<navbar />
		<breadcrumb :list="breadcrumbList" />

		<div class="main flex-direction-column">
			<div class="result-box" v-if="query.type == 1">
				<colorBox style="margin-top: 0" :resultData="resultData" />
				<vehicleInfo style="margin-top: 20px" :resultData="resultData" />
			</div>
			<div class="line" v-if="query.type == 1"></div>
			<div class="result-box">
				<searchGroup :subgroupsTotal="subGroup.length" @change="searchChange" />
				<menuComp v-model="current" :mainGroup="mainGroup" :subGroup="subGroup" @left="leftChange" />
			</div>
		</div>
	</div>
</template>

<script>
import { commonMixins } from "@/mixins/common.js";
export default {
	mixins: [commonMixins],
	data() {
		return {
			current: 0,
			breadcrumbList: [],
			routeQuery: {},
		};
	},

	async mounted() {
		this.query = this.$route.query;
		this.query.type = Number(this.query.type);
		if (this.query.val) this.query.chassis_no = this.query.val;
		if (this.query.type != 1) delete this.query.chassis_no;
		if (this.query.standardnames) {
			this.breadcrumbList = [
				{
					name: this.query.chassis_no || this.query.bread || this.query.val,
					path: "/vehicleResult",
				},
				{
					name: this.query.standardnames,
					path: "/searchResult",
				},
				{
					name: "Main Group",
				},
			];
		} else {
			this.breadcrumbList = [
				{
					name: this.query.chassis_no || this.query.bread || this.query.val,
					path: "/vehicleResult",
				},
				{
					name: "Main Group",
				},
			];
		}
		if (this.query.type === 1) {
			await this.getVehicle();
			await this.getResult();
		}
		this.getMainGroupApi();
	},

	methods: {},
};
</script>

<style scoped lang="less">
.page {
	background-color: #f8f9fb !important;
	padding-bottom: 30px;
	min-height: calc(100vh - 30px);
}
.result-box {
	padding: 25px;
}
.line {
	border-bottom: 1px solid #e6e6e6;
}
.main {
	background-color: #fff;
	width: 1400px;
	margin: 15px auto 0px auto;
	border: 1px solid #e6e6e6;
	.header {
		background: #fffaf5;
	}
	.back {
		cursor: pointer;
		padding: 12px 18px;
		width: fit-content;
		background: #f7f7f7;
		border-radius: 50px;
		margin-top: 15px;
		img {
			width: 16px;
			height: 16px;
		}
		span {
			font-weight: 500;
			font-size: 14px;
			color: #000000;
			margin-left: 10px;
			display: block;
		}
	}
}
.bread-box {
	width: 1400px;
	margin: 0 auto;
	height: 60px;
	display: flex;
	align-items: center;
	.el-icon-location {
		margin-right: 6px;
		font-size: 16px;
	}
	.breadName {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #fd7e14;
		cursor: pointer;
	}
	.separator-icon {
		display: inline-block;
		margin-left: 6px;
		margin-right: 6px;
	}
}
</style>
