<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
	</div>
</template>

<script>
export default {
	name: "app",
	data() {
		return {};
	},
	watch: {},
};
</script>
<style>
html,
body,
#app {
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<style lang="less">
@import url("~@/assets/css/flex.less");
@import url("~@/assets/css/animate.less");
</style>
