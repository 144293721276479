<template>
	<div class="header-box">
		<img src="@/assets/icon/logo.jpg" alt="" class="logo transition" @click="$router.push('/home')" />
	</div>
</template>

<script>
export default {
	name: "JapanApiIndex",

	data() {
		return {};
	},

	mounted() {},

	methods: {},
};
</script>
<style lang="less" scoped>
.transition:hover {
	transform: translateY(-5px) !important;
}
.header-box {
	height: 65px;
	width: 100%;
	display: flex;
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e8e8e8;
	img {
		margin-left: 53px;
		width: 320px;
		height: 50px;
	}
}
</style>
