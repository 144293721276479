<template>
	<div class="imageView" ref="imageView" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseleave="onMouseUp" @mouseup="onMouseUp">
		<img :src="imageUrl" ref="image" @wheel="onWheel" @load="onImageLoad" />
		<p
			ref="pos"
			v-for="(item, index) in transformedCoordinates"
			:key="index"
			:style="{ top: item.y + 'px', left: item.x + 'px', width: item.w + 'px', height: item.h + 'px' }"
			:class="getActive(index) != -1 && 'active'"
			@click="itemPos(index)"
		/>
	</div>
</template>

<script>
export default {
	props: {
		imageUrl: {
			type: String,
			require: true,
		},
		coordinates: {
			type: Array,
			default() {
				return [];
			},
		},
		pos: {
			type: Array,
			default() {
				return [];
			},
		},
		current: [Number, String],
	},
	data() {
		return {
			currentScale: 1, // 当前缩放比例
			isDragging: false, // 是否正在拖拽图片
			startX: 0, // 拖拽起始点x坐标
			startY: 0, // 拖拽起始点y坐标
			translateX: 0, // 图片平移x方向距离
			translateY: 0, // 图片平移y方向距离
			dragSpeed: 1, // 拖拽速度
			zoomSpeed: 0.1, //控制缩放速度
			maxScale: 5, //最大缩放值
			minScale: 0.1, //最小缩放值
			transformedCoordinates: [],
			activeIndex: [],
		};
	},
	created() {
		// 改变窗口大小时重新设置

		window.addEventListener("resize", this.loadImage);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.loadImage);
	},
	methods: {
		getActive(index) {
			return this.activeIndex.findIndex((item) => item === index);
		},
		loadImage() {
			const img = new Image();
			img.onload = () => {
				this.getImageInfo();
			};
			img.src = this.imageUrl;
		},
		onImageLoad() {
			this.getImageInfo();
		},
		// 鼠标按下事件处理函数
		onMouseDown(e) {
			e.preventDefault();
			this.isDragging = true;
			this.startX = e.clientX;
			this.startY = e.clientY;
			this.$refs.image.style.cursor = "grabbing";
		},
		// 鼠标移动事件处理函数
		onMouseMove(e) {
			e.preventDefault();
			if (!this.isDragging) return;

			const deltaX = (e.clientX - this.startX) * this.dragSpeed;
			const deltaY = (e.clientY - this.startY) * this.dragSpeed;
			this.translateX += deltaX;
			this.translateY += deltaY;
			// this.$refs.image.style.transform = `scale(${this.currentScale}) translate(${this.translateX}px, ${this.translateY}px)`;
			this.startX = e.clientX;
			this.startY = e.clientY;
			this.updateTransform();
			this.getImageInfo();
		},
		// 鼠标松开事件处理函数
		onMouseUp() {
			this.isDragging = false;
			this.$refs.image.style.cursor = "grab";
		},
		// 鼠标滚轮事件处理函数
		onWheel(e) {
			e.preventDefault();
			const delta = e.deltaY > 0 ? -1 : 1;

			this.currentScale = Math.min(this.maxScale, Math.max(this.minScale, this.currentScale + delta * this.zoomSpeed));

			this.updateTransform();
			this.getImageInfo();
		},
		// 重置图片缩放和移动
		resetImage() {
			this.currentScale = 1;
			this.translateX = 0;
			this.translateY = 0;
			this.$refs.image.style.transform = "scale(1) translate(0, 0)";
			// 重置 坐标
			this.getImageInfo();
		},
		// 更新图片的变换样式
		updateTransform() {
			this.$refs.image.style.transform = `scale(${this.currentScale}) translate(${this.translateX}px, ${this.translateY}px)`;
		},
		getImageInfo(transition = false) {
			const imageView = this.$refs.imageView;
			const img = this.$refs.image;

			if (transition) {
				// img.classList.add("transition");
				img.style.transition = "transform 1s";
			} else {
				img.style.transition = "";
			}

			const containerWidth = imageView.offsetWidth;
			const containerHeight = imageView.offsetHeight;
			// console.log("containerWidth", containerWidth, containerHeight);

			const originalWidth = img.naturalWidth;
			const originalHeight = img.naturalHeight;
			// console.log("originalWidth", originalWidth, originalHeight);

			const aspectRatio = originalWidth / originalHeight;

			let displayWidth, displayHeight;

			if (containerWidth / containerHeight > aspectRatio) {
				displayHeight = containerHeight * this.currentScale;
				displayWidth = displayHeight * aspectRatio;
			} else {
				displayWidth = containerWidth * this.currentScale;
				displayHeight = displayWidth / aspectRatio;
			}

			const offsetX = (containerWidth - displayWidth) / 2 + this.translateX * this.currentScale;
			const offsetY = (containerHeight - displayHeight) / 2 + this.translateY * this.currentScale;

			const widthRatio = displayWidth / originalWidth;
			const heightRatio = displayHeight / originalHeight;
			// console.log("this.coordinates", this.coordinates);

			if (this.imageUrl.includes("no_img")) return;
			const transformedCoordinates = this.coordinates.map((coord) => ({
				x: coord.x * widthRatio + offsetX,
				y: coord.y * heightRatio + offsetY,
				w: coord.w * widthRatio,
				h: coord.h * heightRatio,
			}));

			this.transformedCoordinates = transformedCoordinates;
		},
		itemPos(index) {
			const { type, pos } = this.coordinates[index];
			if (Number(type) === 3) {
				this.$router.push({
					path: "/mainGroupEngine",
					query: {
						...this.$route.query,
						cate_min_no: pos,
					},
				});
			} else {
				this.$emit("pos", index);
			}
		},
		ensureVisible(index) {
			const imageView = this.$refs.imageView;
			const containerWidth = imageView.offsetWidth;
			const containerHeight = imageView.offsetHeight;
			const item = this.transformedCoordinates[index];

			if (item) {
				const isVisibleX = item.x >= 0 && item.x + item.w <= containerWidth;
				const isVisibleY = item.y >= 0 && item.y + item.h <= containerHeight;

				if (!isVisibleX || !isVisibleY) {
					// const translateX = this.translateX - (item.x + item.w / 2 - containerWidth / 2) / this.currentScale;
					// const translateY = this.translateY - (item.y + item.h / 2 - containerHeight / 2) / this.currentScale;

					this.translateX = 0;
					this.translateY = 0;
					this.currentScale = 1;
					this.updateTransform();
					this.$nextTick(() => {
						this.getImageInfo(true);
					});
				}
			}
		},
	},
	watch: {
		imageUrl() {
			this.loadImage();
		},

		pos: {
			handler(val) {
				if (!val.length) {
					this.activeIndex = [];
					return;
				}

				this.activeIndex = [];
				const positions = new Set(val);

				for (let ic = 0; ic < this.coordinates.length; ic++) {
					const { pos } = this.coordinates[ic];
					if (positions.has(pos)) {
						if (!this.activeIndex.includes(ic)) {
							this.activeIndex.push(ic);
						}
					}
				}

				this.ensureVisible(val[val.length - 1]);
			},
		},
	},
};
</script>

<style lang="less" scoped>
.active {
	border: 1.5px solid #fd7e14;

	background-color: rgba(80, 149, 251, 0.6);
}
.imageView {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	padding-bottom: "*";
}

.imageView .elImageclass {
	width: 100%;
	height: 100%;
	touch-action: none;
	user-drag: none;
	-webkit-user-drag: none;
}

img {
	width: 100%;
	height: 100%;
	cursor: grab;
	object-fit: contain !important;
	// transition: transform 0.5s;
}
.transition {
	transition: transform 0.3s;
}
p {
	cursor: pointer;
	position: absolute;
	margin: 0;
	padding: 0;
	border: 1px solid #fd7e14;
	// border: 1px solid #000;
}
</style>
