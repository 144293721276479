<template>
	<div class="vehicle-info flex">
		<div class="vehicle-left">
			<img src="@/assets/icon/car.png" alt="" />
			<div class="info">Vehicle Info.</div>
		</div>
		<div class="vehicle-right" v-if="resultData">
			<div class="vehicle-item">
				<div class="item"><span class="label">Brand：</span>{{ resultData.brand_name || "-" }}</div>
				<div class="item"><span class="label">Model Name：</span>{{ resultData.car_model || "-" }}</div>
				<div class="item"><span class="label">Vehicle Code：</span>{{ resultData.chassis_no || "-" }}</div>
				<div class="item"><span class="label">production_date：</span>{{ resultData.production_date || "-" }}</div>
			</div>
			<div class="vehicle-item" style="margin-top: 24px">
				<div class="item" v-for="(item, index) in resultData.config" :key="index">
					<span class="label">{{ item.title }}：</span>{{ item.name || "-" }}
				</div>
			</div>
		</div>
		<!-- <div class="icon" v-if="resultData">
			<img src="@/assets/icon/arrow-right-active.png" alt="" />
		</div> -->
	</div>
</template>

<script>
export default {
	name: "JapanApiIndex",
	props: {
		resultData: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {};
	},

	mounted() {
		// console.log("resultData", this.resultData);
	},

	methods: {},
};
</script>

<style scoped lang="less">
.icon {
	margin-left: 10px;
	img {
		width: 18px;
		height: 18px;
	}
}
.vehicle-info {
	background: #fffaf5;
	// background: #f4f4f4;
	padding: 18px 21px;
}

.vehicle-left {
	text-align: center;
	border-right: 1px solid #e6e6e6;
	height: 70px;
	//width: 92px;
	padding: 0 20px 0 0;
	// padding-right: 19px;
	img {
		margin-top: 10px;
	}
}
.vehicle-right {
	flex: 1;
	margin-left: 20px;
}
.vehicle-item {
	display: flex;
	flex-wrap: wrap;
	.item {
		width: 24%;
		margin-right: 10px;
		&:nth-last-child(5n) {
			margin-right: 0;
		}
	}
	.label {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #999999;
	}
}
</style>
