export default [
    {
        name:'Disc brake pad',
        znname:'刹车片',
        url:'Disc brake pad.png'
    },
     {
        name:'Brake shoe',
        znname:'制动蹄',
        url:'Brake shoe.png'
    }
]