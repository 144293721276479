<template>
	<div class="search-result-container">
		<div class="setting">
			<i class="el-icon-s-tools"></i>
			<span>Search Result</span>
		</div>
		<div class="search-form" v-if="isShow">
			<el-input v-model="searchVal" placeholder="Vehicle Code"></el-input>
			<div @click="btnClick" class="search-box"><i class="el-icon-search"></i></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "JapanApiIndex",
	props: {
		isShow: {
			type: Boolean,
			default() {
				return true;
			},
		},
	},
	data() {
		return {
			searchVal: "",
		};
	},

	mounted() {},

	methods: {
		btnClick() {
			if (!this.searchVal) return;
			this.$emit("change", this.searchVal);
		},
	},
};
</script>

<style scoped lang="less">
.search-result-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 22px;
	margin-top: 20px;
	.search-box {
		width: 80px;
		height: 40px;
		line-height: 40px;
		background: #fd7e14;
		border-radius: 0px 100px 100px 0px;
		color: #fff;
		text-align: center;
	}
	.search-form {
		display: flex;
		cursor: pointer;
		justify-content: flex-start;
		/deep/ .el-input__inner {
			border-radius: 100px 0px 0px 100px;
			border-right: none;
		}
	}
	.setting {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #000000;
		i {
			font-size: 20px;
			margin-right: 10px;
		}
	}
}
</style>
