<template>
	<div class="menu flex-space-between" v-if="mainGroup.length">
		<div class="left flex-direction-column" v-if="isShowLeft">
			<div class="item transition flex-space-between" v-for="(item, index) in mainGroup" :key="item.cate1_no || index" :class="index === activeIndex && 'active'" @click="leftClick(item, index)">
				<span>{{ index + 1 }}.</span>
				<span>{{ item.cate1_name || item.oe_name }}</span>
				<img v-if="index === activeIndex" src="@/assets/icon/arrow-right-active.png" alt="" />
				<img v-else src="@/assets/icon/arrow-right.png" alt="" />
			</div>
		</div>
		<div class="right" v-if="isShowLeft ? subGroup.length : mainGroup.length">
			<div class="item" :style="{ width: indexNum + '%' }" v-for="(item, index) in isShowLeft ? subGroup : mainGroup" :key="item.cate2_no || index" @click="toEngineList(item)">
				<!-- <div class="index">{{ index + 1 }}.</div> -->
				<div class="contner flex">
					<div class="c-left">
						<img :src="item.cate_min_img" alt="" />
					</div>
					<div class="c-right">
						<span class="flex">{{ item.cate_min_name }}</span>
						<!-- <span>Subgroup_code：{{ item.cate2_no }}</span> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="empty">No data found</div>
</template>

<script>
export default {
	name: "JapanApiIndex",
	props: {
		value: [String, Number, Object, Array],
		subGroup: {
			type: Array,
			default() {
				return [];
			},
		},
		isShowLeft: {
			type: Boolean,
			default() {
				return true;
			},
		},
		mainGroup: {
			type: Array,
			default() {
				return null;
			},
		},
		searchCode: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			activeIndex: 0,
			indexNum: 19,
		};
	},

	mounted() {
		const _this = this;
		// 画echars
		// 监听浏览器窗口变化
		window.onresize = function () {
			return (() => {
				window.screenWidth = document.body.clientWidth;
				if (window.screenWidth > 768 && window.screenWidth < 1240) {
					_this.indexNum = 20;
				} else if (window.screenWidth > 1240) {
					_this.indexNum = 19;
				} else if (window.screenWidth < 768) {
					_this.indexNum = 30;
				}
				console.log(window.screenWidth, "window.screenWidth", _this.indexNum);
			})();
		};
	},

	methods: {
		leftClick(item, index) {
			this.activeIndex = index;
			this.$emit("left", {
				item,
				index,
			});
		},
		toEngineList({ cate_min_no, cate_min_name, pos }) {
			// const { cate1_no, oe_name, cate1_name, pos } = this.mainGroup[this.activeIndex];
			const { cate1_no, oe_name, cate1_name } = this.mainGroup[this.activeIndex];

			this.$router.push({
				path: "/mainGroupEngine",
				query: {
					...this.$route.query,
					cate_min_no,
					cate1_no,
					cate_min_name,
					pos: pos ?? this.mainGroup[this.activeIndex].pos,
					cate_main_name: oe_name || cate1_name,
				},
			});
		},
	},
	watch: {
		value: {
			handler(val) {
				this.activeIndex = val;
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>

<style lang="less" scoped>
.active {
	background: #fffaf5 !important;
	border: 1px solid #ffac66 !important;
	span {
		color: #fd7e14 !important;
	}
}
.transition:hover {
	transform: translateY(-5px) !important;
}
.menu {
	align-items: flex-start;
	margin-top: 20px;
	.left {
		.item {
			cursor: pointer;
			width: 370px;
			padding: 0 15px;
			height: 75px;
			background: #f7f7f7;
			border-radius: 4px;
			margin-bottom: 10px;
			border: 1px solid #f7f7f7;

			span {
				font-weight: 500;
				font-size: 16px;
				color: #000000;
				&:nth-child(2) {
					flex: 1;
					margin: 0 15px;
				}
			}
			img {
				width: 18px;
				height: 18px;
			}
		}
	}
	.right {
		flex: 1;
		margin-left: 20px;
		display: flex;
		flex-wrap: wrap;
		// border: 1px solid #e6e6e6;
		.item {
			cursor: pointer;
			padding: 20px 0;
			border: 1px solid #e6e6e6;
			// border-bottom: none;
			// border-bottom: 1px solid #e6e6e6;
			// border-right: 1px solid #e6e6e6;
			display: flex;
			flex-direction: column;
			// width: 19%;

			// &:last-child {
			// 	border: 1px solid #e6e6e6;
			// }
			.index {
				font-size: 16px;
				color: #000000;
			}
			.contner {
				flex: 1;
				// margin: 0 20px;
				height: 68px;
				display: flex;
				flex-direction: column;
				.c-left {
					height: 100%;
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				.c-right {
					flex: 1;
					height: 100%;
					padding: 8px 10px;
					align-items: center;
					text-align: center;
					justify-content: center;
					span {
						&:nth-child(1) {
							font-size: 14px;
							color: #000000;
						}
						&:nth-child(2) {
							font-size: 14px;
							color: #000000;
						}
					}
				}
			}
			.arrow {
				img {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}
.empty {
	margin-top: 20px;
	color: #999999;
}
</style>
